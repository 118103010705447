import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "activity"
};
const _hoisted_2 = {
  class: "activity-container"
};
const _hoisted_3 = {
  class: "activity-tab"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "activity-content"
};
const _hoisted_6 = ["innerHTML"];
import { router } from '@/router';
import { ref } from 'vue';
import { Activity } from '@/api';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'Activity',
  setup(__props) {
    const {
      t
    } = useI18n();
    const onClickLeft = () => {
      router.back();
    };
    // const typeNames = ['Recharge Rewards','Reset Rewards','Sign In Rewards','Random Rewards','Activity Rewards','7 Day Rewards']
    const typeNames = [{
      value: 'recharge_rewards',
      name: t('Recharge Rewards')
    }, {
      value: 'reset_rewards',
      name: t('Reset Rewards')
    }, {
      value: 'sign_in_rewards',
      name: t('Sign In Rewards')
    }, {
      value: 'random_rewards',
      name: t('Random Rewards')
    }, {
      value: 'activity_rewards',
      name: t('Activity Rewards')
    }, {
      value: '7_day_rewards',
      name: t('7 Day Rewards')
    }];
    const activityData = ref({
      content: ''
    });
    const type = ref('recharge_rewards');
    const tapTab = value => {
      type.value = value;
      _Activity();
    };
    const _Activity = async () => {
      Activity({
        type: type.value
      }).then(({
        code,
        data
      }) => {
        if (code === 200 && data) {
          activityData.value = data;
        } else {
          activityData.value = {
            content: ''
          };
        }
      });
    };
    _Activity();
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-grey__bg",
        fixed: "",
        title: _ctx.$t('Activity'),
        placeholder: "",
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(typeNames, (item, index) => {
        return _createElementVNode("div", {
          class: _normalizeClass(["activity-tab__item", {
            'activity-tab__active': item.value === type.value
          }]),
          key: index,
          onClick: $event => tapTab(item.value)
        }, _toDisplayString(item.name), 11, _hoisted_4);
      }), 64))]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
        innerHTML: activityData.value.content
      }, null, 8, _hoisted_6)])])]);
    };
  }
};